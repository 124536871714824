<template>
    <section v-loading="loading_recursos" :element-loading-text="`${mensaje_carga}`">
        <header-page title="Editar Curso" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 220px);">
            <div class="row mx-0 h-100 py-3">
                <div class="col border-right pt-4">
                    <div class="row mx-0">
                        <div class="col-12">
                            <el-input v-model="curso.nombre" class="w-100 input-name" maxlength="120" placeholder="Nombre..." @change="updateBasico" />
                        </div>
                        <div class="col-12 mt-3">
                            <label class="text-gris mb-2 pl-3"> Descripción </label>
                            <el-input v-model="curso.descripcion" type="textarea" placeholder="Descripccion del curso" maxlength="2000" class="w-100 area-radius" :rows="8" @change="updateBasico" />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 mt-3">
                            <span class="f-600">Abierto:</span> El lector podrá acceder a todos los temas sin un orden específico. <br />
                            <span class="f-600">Progresivo:</span> El lector accederá a los temas solo en el orden establecido.
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 border-bottom pb-3">
                        <div class="col-auto">
                            <el-radio v-model="curso.tipo" :label="1" @change="updateTipo">
                                <span class="f-18">
                                    Abierto
                                </span>
                            </el-radio>
                        </div>
                        <div class="col-auto">
                            <el-radio v-model="curso.tipo" :label="2" @change="updateTipo">
                                <span class="f-18">
                                    Progresivo
                                </span>
                            </el-radio>                     
                        </div>
                    </div>
                    <div class="row mx-0 j-center my-3">
                        <el-upload
                        ref="upload"
                        class="upload-demo"
                        action="#"
                        :auto-upload="false"
                        multiple
                        :limit="10"
                        :show-file-list="false"
                        :on-change="subir_recurso_aws"
                        >
                            <el-button size="small bg-white f-18 text-gris shadow br-12 py-2 px-3 cr-pointer">
                                Subir recursos descargables
                            </el-button>
                        </el-upload>
                    </div>
                   
                    <div v-for="(r,index) in recursos" :key="index" class="row mx-0 a-center">
                        <div class="col-auto pr-0">
                            <i class="text-general f-20" :class="icon_extension(r.nombre)" />
                        </div>
                        <div class="col tres-puntos text-gris">
                            {{ r.nombre }}
                        </div>
                        <div class="col-auto">
                            <i class="icon-cancel text-general f-22 cr-pointer" @click="openModalEliminar(r.id)" />
                        </div>
                    </div>
                </div>
                <div class="col border-left">
                    <div class="row mx-0 my-3 border-bottom">
                        <router-link 
                        v-for="(ruta, r) in rutas" 
                        :key="r" 
                        class="col-auto pb-2" 
                        :class="$route.name == ruta.name ? 'current-tab f-20' : 'tab f-18'"
                        :to="{name: ruta.name}"
                        >
                            {{ ruta.titulo }}
                        </router-link>
                    </div>
                    <router-view />
                </div>
            </div>
        </div>
        <modalSiNo
        ref="modalEliminarRecurso"
        titulo="Eliminar Recurso"
        mensaje="¿Realmente desea eliminar el recurso?"
        @accion="eliminarRecurso"
        />
    </section>
</template>

<script>
import Cursos from '~/services/cursos/gestionar'
import Aws from '~/services/aws'
export default {
    components: {
        // selectResponsable: () => import('../../components/selectResponsable')
    },
    layout: 'fullscreen',
    data(){
        return {
            nombre: '',
            rutas: [
                {
                    name: 'mis-cursos.editar.curso.tema',
                    titulo: 'Temas'
                },
                {
                    name: 'mis-cursos.editar.curso.lectores',
                    titulo: 'Lectores'
                }, 
                {
                    name: 'mis-cursos.editar.curso.entregas',
                    titulo: 'Entregas'
                },
                {
                    name: 'mis-cursos.editar.curso.responsables',
                    titulo: 'Responsables'
                }
            ],
            curso:{
                nombre:'',
                descripcion:'',
                tipo:0
            },
            mensaje_carga:'',
            contador:0,
            contTemp:1,
            loading_recursos:false,
            model_recursos:[],
            recursos:[],
            id_eliminar:null
        }
    },
    computed:{
        id_curso(){
            return Number(this.$route.params.id_curso)
        }
    },
    mounted(){
        this.infoBasica()
        this.getRecursos()
    },
    methods:{
        async infoBasica(){
            const {data} = await Cursos.infoBasica(this.id_curso);
            this.curso  = data.data.curso
        },
        async getRecursos(){
            const {data} = await Cursos.getRecursos(this.id_curso);
            this.recursos  = data.data.recursos
        },
        async subir_recurso_aws(file,filelist){
            let that = this
            try {

                const isLt100M = file.size / 1000 / 1000 < 100;
                if (!isLt100M){
                    filelist.splice(file,1)
                    this.notify(`El archivo ${file.name} excede los 100MB`,'warning')
                    return
                }

                that.loading_recursos = true
                that.contador = filelist.length
                let ruta = `descargables/${Aws.getHash()}.${file.name.split('.').pop()}`
                let resp = await Aws.getInstance().upload({
                    Key: ruta,
                    ContentType: file.raw.type,
                    Body: file.raw
                }).on('httpUploadProgress',uploadEvent=>{
                    let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                    that.mensaje_carga = `Recursos ${that.contTemp}/${filelist.length} => ${porcentaje}%`
                }).promise()
                that.contTemp++
                this.model_recursos.push({ruta:resp.Key, nombre:file.name})
                that.contador--
                if(that.contador === 0){
                    that.almacenarRecursos()
                }
            } catch (e){
                this.loading_recursos = false
                this.error_catch(e)
            }
            
        },
        async almacenarRecursos(){

            try {
                if(!this.id_curso){
                    this.notificacion('Alerta','Accion Invalidad, Por favor seleccione un curso valido','warning')
                    return 
                }
                const {data} = await Cursos.almacenarRecursos(this.id_curso,this.model_recursos)
                this.getRecursos()
                this.notify('Recursos almacenados correctamente','success')
                this.mensaje_carga = '',
                this.contador = 0,
                this.contTemp = 1,
                this.loading_recursos = false,
                this.$refs.upload.clearFiles()
                this.model_recursos = []

            } catch (e){
                this.$refs.upload.clearFiles()
                this.loading_recursos = false
                this.error_catch(e)
            }

        },
        openModalEliminar(id){
            this.id_eliminar = id
            this.$refs.modalEliminarRecurso.toggle()
        },
        async eliminarRecurso(res){
            try {
                if(res){
                    const {data} = await Cursos.eliminarRecurso(this.id_eliminar)
                    this.getRecursos()
                    this.notify('Recurso eliminado','success')
                }
                this.$refs.modalEliminarRecurso.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateBasico(){
            try {

                if(this.curso.nombre == ''){
                    this.notify('El nombre del curso es requerido..','error')
                    this.infoBasica()
                    return
                }

                let model = {
                    nombre:this.curso.nombre,
                    descripcion:this.curso.descripcion
                }

                const {data} = await Cursos.updateInfoBasico(this.id_curso,model)
                this.notify('Éxito, Datos Actualizados','success')

            } catch (error){
                this.infoBasica()
                this.error_catch(error) 
            }
        },
        async updateTipo(val){
            try {

                let model = {
                    tipo:val
                }
                const {data} = await Cursos.updateTipo(this.id_curso,model)
                this.notify('El tipo del curso ha sido actualizado','success')

            } catch (error){
                this.infoBasica()
                this.error_catch(error) 
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>